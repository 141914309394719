














































































































































































import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import * as Config from '@/config';

export interface DataType {
  shareKeyBeValid: boolean;
  loading: boolean;
  error: string;
  isMfa: boolean;
  isFederatedLoginService: boolean;
}

export default Vue.extend({
  name: 'DashBoard',
  components: {
    Breadcrumbs,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$emit('loading', false);
    this.$emit('activeMenu', 'dashboard');
  },
  created() {
    this.getPlan();
  },
  data: (): DataType => ({
    shareKeyBeValid: false,
    loading: false,
    error: '',
    isMfa: false,
    isFederatedLoginService: false,
  }),

  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ホーム', disabled: true },
        ];
      },
    },
  },
  methods: {
    getPlan() {
      this.isMfa = localStorage.isMfa;
      this.isFederatedLoginService = localStorage.isFederatedLoginService;
    },
    openPage(value: any) {
      window.open(value, '_blank');
    },
  },
});
